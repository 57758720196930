import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import { useState } from "react";
import moment from "moment";
import { useGetNextRacesQuery } from "../../features/api/apiSlice";
import { Outlet, useParams, Link } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

export default function NextRaces(props) {
  const returnNav = false;
  const [drawerOpen] = useState(false);
  let { raceNumber } = useParams();
  raceNumber = Number(raceNumber);

  let races;

  const { data: nextRaces, isSuccess: nextRacesIsSuccess } =
    useGetNextRacesQuery(null, { pollingInterval: 30000 });

  if (nextRacesIsSuccess) {
    races = nextRaces.map((a) => {
      let borderColor = "blue";

      if (a.groupRace === "G1") {
        borderColor = "gold";
      } else if (a.groupRace === "G2") {
        borderColor = "silver";
      } else if (a.groupRace === "G3") {
        borderColor = "orange";
      }

      return (
        <Link
          style={{
            margin: "10px",
            flex: "1 1 0%",
            border: "solid 1px",
            borderColor: borderColor,
            padding: "10px",
            borderRadius: "10px",
            flexDirection: "column",
            display: "flex",
            textAlign: "left",
          }}
          variant={a.number === raceNumber ? "contained" : "outlined"}
          to={`/races/${moment(a.startTime).format("YYYYMMDD")}/meeting/${
            a.meeting.code
          }/race/${a.raceNumber}`}
          key={a.number}
        >
          <div
            style={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
              fontSize: "14px",
            }}
          >
            <span>
              Race {a.raceNumber} - {a.name}
            </span>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flex: "1",
                flexDirection: "row",
                fontSize: "14px",
              }}
            >
              <div style={{ flex: "1" }}>{moment(a.startTime).fromNow()}</div>
            </div>
          </div>
        </Link>
      );
    });
  }

  const display = returnNav ? "none" : "initial";

  return (
    <div
      style={{
        backgroundColor: "#282c34",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "calc(10px + 2vmin)",
        color: "white",
        flex: "1",
        scrollMarginTop: "200px",
      }}
    >
      <div
        expanded={drawerOpen}
        style={{
          display: display,
          zIndex: 11,
        }}
        sx={{
          margin: "0px",
          "& .MuiAccordionSummary-contentGutters": {
            margin: "0px",
          },
          boxShadow: "none",
          color: "white",
        }}
        className="accordionPanel baseAccordion"
      >
        <div>
          <Root>
            <StyledBox
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                color: "black",
              }}
            >
              <h3 style={{ marginTop: "20px", margin: "10px" }}>Next to go</h3>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  width: "100%",
                  paddingBottom: "200px",
                }}
              >
                {races}
              </div>
            </StyledBox>
          </Root>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
