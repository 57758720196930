import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, onValue, query } from "firebase/database";

export function createComment(horse, user, comment, databaseReferenceString) {
  // TODO: Replace the following with your app's Firebase project configuration
  // See: https://firebase.google.com/docs/web/learn-more#config-object
  const firebaseConfig = {
    apiKey: "AIzaSyBTgM9UQZJgy5WHtynvchywyfF0Klul4Ck",
    authDomain: "racingpicks-b8b05.firebaseapp.com",
    databaseURL: "https://racingpicks-b8b05-default-rtdb.firebaseio.com",
    projectId: "racingpicks-b8b05",
    storageBucket: "racingpicks-b8b05.appspot.com",
    messagingSenderId: "975858243227",
    appId: "1:975858243227:web:d435522fd5ab90f00dd83e",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const database = getDatabase(app);

  // Update the document

  const db = query(ref(database, "horseComments/" + horse.name));

  const timeStamp = new Date().getTime();

  onValue(
    db,
    (snapshot) => {
      push(ref(database, "horseComments/" + horse.name), {
        horseDetails: horse,
        user: user,
        comment: comment,
        timeStamp: timeStamp,
      });
    },
    {
      onlyOnce: true,
    }
  );
}
