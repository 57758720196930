export default function MeetingHeader(props) {
  const { meeting } = props;

  let trackName = meeting.venue.replace(" ", "");

  return (
    <div
      style={{
        width: "100%",
        boxShadow: "none",
        color: "white",
      }}
    >
      <div
        style={{
          backgroundColor: "black",
          padding: "0px",
          margin: "10px",
          borderRadius: "10px",
          overflow: "hidden",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            height: "210px",
            backgroundImage: `url(/RaceTracks/${trackName}.jpg)`,
            backgroundSize: "cover",
            width: "100%",
          }}
        >
          <div
            style={{
              position: "relative",
              zIndex: "2",
              fontWeight: "bold",
              textShadow: "3px 3px 4px black",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              color: "white",
            }}
          >
            <div
              style={{
                fontSize: "18px",
                textAlign: "left",
                padding: "10px",
                flex: 1,
              }}
            >
              {meeting.name}
              <div style={{ fontSize: "16px" }}>
                <br></br>
                {meeting.venue}
              </div>
            </div>
            <div
              style={{
                textAlign: "left",
                padding: "10px",
                fontSize: "16px",
                display: "flex",
              }}
            >
              <div style={{ flex: 1 }}>
                Weather: {meeting.races[0].weather}{" "}
              </div>
              <div style={{ flex: 1 }}>Track: {meeting.races[0].track} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
