/* eslint-disable */

import logo from "./logo.svg";
import "./App.css";
import Login from "./pages/Login";
import RacePage from "./pages/RacePage";
import HorseCard from "./components/HorseCard";
import BottomNav from "./components/BottomNav";
import TopNav from "./components/TopNav";
import { useEffect, useState, useRef } from "react";
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  set,
  update,
  push,
  onValue,
  remove,
} from "firebase/database";
import moment from "moment";
import _ from "lodash";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import BedroomBabyIcon from "@mui/icons-material/BedroomBaby";

import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import { useCookies } from "react-cookie";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MyList from "./pages/MyList";
import WhatsHot from "./pages/WhatsHot";
import TrifectaResults from "./pages/TrifectaResults";

import localforage from "localforage";
import { useListVals } from "react-firebase-hooks/database";

// Import Fonts
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// import { setUser } from '../features/user/userSlice'
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setUser, clearUser } from "./features/user/userSlice";
import { ErrorBoundary } from "react-error-boundary";
import {
  useGetScheduleQuery,
  useGetRaceFormQuery,
} from "./features/api/apiSlice";
import SingleRace from "./pages/SingleRace";
import SingleHorse from "./pages/SingleRace/SingleHorse";
import Meeting from "./pages/Meeting/Meeting";
import NextRaces from "./pages/NextRaces/NextRaces";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
  useNavigate,
  ScrollRestoration,
} from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyBTgM9UQZJgy5WHtynvchywyfF0Klul4Ck",
  authDomain: "racingpicks-b8b05.firebaseapp.com",
  databaseURL: "https://racingpicks-b8b05-default-rtdb.firebaseio.com",
  projectId: "racingpicks-b8b05",
  storageBucket: "racingpicks-b8b05.appspot.com",
  messagingSenderId: "975858243227",
  appId: "1:975858243227:web:d435522fd5ab90f00dd83e",
};

function App() {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [raceMeeting, setRaceMeeting] = useState(4);
  // const raceMeeting = 2;
  const [raceNumber, setRaceNumber] = useState(4);
  // const raceNumber = 4;
  const [defaultRace, setDefaultRace] = useState(true);
  const [runners, setRunners] = useState([]);
  const [favourites, setFavourites] = useState([]);
  const [schedDate, setSchedDate] = useState(
    moment(date).day("Saturday").format("YYYY-MM-DD")
  );
  const [odds, setOdds] = useState([]);
  const [meetingLinks, setMeetingLinks] = useState([]);
  const [tabRaceLink, setTabRaceLink] = useState(null);
  const [showMyList, setShowMyList] = useState(false);

  const user = useSelector((state) => state.user.currentUser);
  const loggedIn = useSelector((state) => state.user.loggedIn);

  const { data, isLoading, isSuccess, isError, error } =
    useGetScheduleQuery(schedDate);

  const currentRace = data?.meetings
    .find((a) => a.number === raceMeeting)
    ?.races.find((a) => a.number === raceNumber);

  const currentMeetingDetails = data?.meetings.find(
    ({ number }) => number === raceMeeting
  );
  const currentRaceDetails = currentMeetingDetails?.races[raceNumber - 1];

  const dispatch = useDispatch();

  localforage.getItem("link", function (err, value) {
    // if err is non-null, we got an error. otherwise, value is the value
    if (value != null) {
      const link = value;
      localforage.removeItem("link").then(function () {
        // Run this code once the key has been removed.
        window.location.href = link;
      });
    }
  });

  // can we get/set the user cookie?
  const [cookies, setCookie, removeCookie] = useCookies(["ShitPicks"]);

  useEffect(() => {
    if (!defaultRace) {
      setCookie("lastRace", raceNumber, { path: "/" });
      setCookie("lastMeeting", raceMeeting, { path: "/" });
    }
    setDefaultRace(false);
  }, [raceNumber, raceMeeting]);

  useEffect(() => {
    if (cookies.lastRace != null && cookies.lastRace !== undefined) {
      setRaceNumber(Number(cookies.lastRace));
      setRaceMeeting(Number(cookies.lastMeeting));
    }
  }, [cookies]);

  // Initialize Realtime Database and get a reference to the service

  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);

  const [latestNotification] = useListVals(
    ref(database, "users/" + user + "/latestNotification")
  );
  console.log(latestNotification);
  if (latestNotification.length !== 0) {
    const link = latestNotification[0].link;
    set(ref(database, "users/" + user + "/latestNotification"), null);
    window.location.href = link;
  }

  const Root = () => {
    return (
      <ErrorBoundary
        fallbackRender={fallbackRender}
        onReset={(details) => {
          const [removeCookie] = useCookies();

          removeCookie("lastRace", { path: "/" });
          removeCookie("lastMeeting", { path: "/" });
          removeCookie("user", { path: "/" });

          // Reset the state of your app so the error doesn't happen again
        }}
      >
        <TopNav
          removeCookie={() => {
            dispatch(clearUser(null));
          }}
        />
        <div
          className="App"
          style={{
            maxWidth: "100vw",
            backgroundColor: "rgb(40, 44, 52)",
            display: "flex",
            maxHeight: "100vh",
            flexDirection: "column",
            zIndex: "4",
          }}
        >
          <Outlet />
        </div>
        <ScrollRestoration />
      </ErrorBoundary>
    );
  };

  function fallbackRender({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
        <Button
          onClick={() => {
            resetErrorBoundary();
          }}
          href="/"
        >
          Reload Page
        </Button>
      </div>
    );
  }

  if (!loggedIn) return <Login />;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route
          path="MyList"
          element={
            <MyList
              favourites={favourites}
              date={schedDate}
              user={user}
              updateRaceMeeting={setRaceMeeting}
              updateRaceNumber={setRaceNumber}
              upcomingRaceScreen={setShowMyList}
            ></MyList>
          }
        />
        <Route
          path="WhatsHot"
          element={
            <WhatsHot
              favourites={favourites}
              date={schedDate}
              user={user}
              updateRaceMeeting={setRaceMeeting}
              updateRaceNumber={setRaceNumber}
              upcomingRaceScreen={setShowMyList}
            ></WhatsHot>
          }
        />
        <Route
          path="TrifectaResults"
          element={
            <TrifectaResults
              favourites={favourites}
              date={schedDate}
              user={user}
              updateRaceMeeting={setRaceMeeting}
              updateRaceNumber={setRaceNumber}
              upcomingRaceScreen={setShowMyList}
            ></TrifectaResults>
          }
        />
        <Route path="/races/next" element={<NextRaces />} />
        <Route path="/" element={<RacePage />} />
        <Route path="/races/:date/meeting/:raceMeeting">
          <Route path="race/:raceNumber">
            <Route
              path="runner/:runner"
              element={
                <SingleHorse
                  data={data}
                  isSuccess={isSuccess}
                  updateRaceMeeting={setRaceMeeting}
                  updateRaceNumber={setRaceNumber}
                  updateSchedDate={setSchedDate}
                  currentMeetingDetails={currentMeetingDetails}
                  currentRaceDetails={currentRaceDetails}
                  user={user}
                />
              }
            ></Route>
            <Route
              index
              element={
                <SingleRace
                  data={data}
                  isSuccess={isSuccess}
                  updateRaceMeeting={setRaceMeeting}
                  updateRaceNumber={setRaceNumber}
                  updateSchedDate={setSchedDate}
                  currentMeetingDetails={currentMeetingDetails}
                  currentRaceDetails={currentRaceDetails}
                  user={user}
                  trifecta={false}
                />
              }
            />
          </Route>
          <Route index element={<Meeting trifecta={false} />} />
        </Route>
        <Route path="/trifecta/:date/meeting/:raceMeeting">
          <Route path="race/:raceNumber">
            <Route
              path="runner/:runner"
              element={
                <SingleHorse
                  data={data}
                  isSuccess={isSuccess}
                  updateRaceMeeting={setRaceMeeting}
                  updateRaceNumber={setRaceNumber}
                  updateSchedDate={setSchedDate}
                  currentMeetingDetails={currentMeetingDetails}
                  currentRaceDetails={currentRaceDetails}
                  user={user}
                />
              }
            ></Route>
            <Route
              index
              element={
                <SingleRace
                  data={data}
                  isSuccess={isSuccess}
                  updateRaceMeeting={setRaceMeeting}
                  updateRaceNumber={setRaceNumber}
                  updateSchedDate={setSchedDate}
                  currentMeetingDetails={currentMeetingDetails}
                  currentRaceDetails={currentRaceDetails}
                  user={user}
                  trifecta={true}
                />
              }
            />
          </Route>
          <Route index element={<Meeting trifecta={true} />} />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
