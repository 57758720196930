/* eslint-disable */
import HorseCard from "../../components/HorseCard";
import HorseCardTrifecta from "../../components/HorseCardTrifecta";
import SingleHorse from "./SingleHorse";
import RaceDetails from "./RaceDetails";

import { useEffect, useState, useRef, useMemo } from "react";

import moment from "moment";
import _ from "lodash";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import BedroomBabyIcon from "@mui/icons-material/BedroomBaby";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import { useCookies } from "react-cookie";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  useList,
  useListVals,
  useObjectVal,
} from "react-firebase-hooks/database";
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  set,
  update,
  push,
  onValue,
  remove,
  query,
  orderByChild,
  equalTo,
  endAt,
} from "firebase/database";

// Import Fonts
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// import { setUser } from '../features/user/userSlice'
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ErrorBoundary } from "react-error-boundary";
import {
  useGetScheduleQuery,
  useGetRaceFormQuery,
  useGetRaceOddsQuery,
  useGetRaceLinksQuery,
  useGetRaceIDsQuery,
} from "../../features/api/apiSlice";
import { useParams, useNavigate } from "react-router-dom";
import Fab from "@mui/material/Fab";
import BackIcon from "@mui/icons-material/ArrowBack";
import ForwardIcon from "@mui/icons-material/ArrowForward";

export default function SingleRace(props) {
  const { user, trifecta } = props;
  const [tabValue, setTabValue] = useState(0);
  const [currentTab, setCurrentTab] = useState("basic");
  const [singleHorse, setSingleHorse] = useState(null);
  const [raceGroupBorderColor, setRaceGroupBorderColor] = useState("black");
  const showReturnNav = null;
  let { raceNumber, raceMeeting, date } = useParams();
  const navigate = useNavigate();
  const [allFavourites, setAllFavourites] = useState();

  raceNumber = Number(raceNumber);
  raceMeeting = Number(raceMeeting);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const tabTypes = ["basic", "extended", "ratings"];
    setTabValue(newValue);
    setCurrentTab(tabTypes[newValue]);
  };

  const { data, isLoading, isSuccess, isError, scheduleError } =
    useGetScheduleQuery(moment(date).format("YYYY-MM-DD"));

  const race = data?.meetings
    .find((a) => a.number === raceMeeting)
    ?.races.find((a) => a.number === raceNumber);

  const raceMeetingDetails = data?.meetings.find(
    ({ number }) => number === raceMeeting
  );

  const firebaseConfig = {
    apiKey: "AIzaSyBTgM9UQZJgy5WHtynvchywyfF0Klul4Ck",
    authDomain: "racingpicks-b8b05.firebaseapp.com",
    databaseURL: "https://racingpicks-b8b05-default-rtdb.firebaseio.com",
    projectId: "racingpicks-b8b05",
    storageBucket: "racingpicks-b8b05.appspot.com",
    messagingSenderId: "975858243227",
    appId: "1:975858243227:web:d435522fd5ab90f00dd83e",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const database = getDatabase(app);

  const [meetingFavourites, loading, error] = useObjectVal(
    ref(database, "raceMeetings/" + date + "/Meeting" + raceMeeting),
    {
      keyField: "key",
    }
  );

  const favourites = _.values(meetingFavourites?.["Race" + raceNumber]);

  const databaseReferenceString =
    "raceMeetings/" + date + "/Meeting" + raceMeeting + "/Race" + raceNumber;

  const { data: raceIDs, isSuccess: raceIDSuccess } = useGetRaceIDsQuery(
    moment(date).format("YYYY-MM-DD")
  );
  const getRaceID = (meetingNumb, raceNumb) => {
    if (!raceIDSuccess) return null;

    const meetingDeets = _.toArray(raceIDs.meetings);
    const raceID = meetingDeets.filter((e) => e.retail_code == raceMeeting)[0]
      .race_ids[raceNumber - 1];
    return raceID;
  };

  //Get RaceForm
  const {
    data: raceForm,
    isLoading: raceFormIsLoading,
    isSuccess: raceFormIsSuccess,
    isError: raceFormIsError,
    error: raceFormError,
  } = useGetRaceFormQuery(getRaceID(raceMeeting, raceNumber));

  const {
    data: raceOdds,
    isLoading: raceOddsIsLoading,
    isSuccess: raceOddsIsSuccess,
    isError: raceOddsIsError,
    error: raceOddsError,
  } = useGetRaceOddsQuery({
    date: date,
    meeting: raceMeeting,
    race: raceNumber,
  });

  const {
    data: raceLinks,
    isLoading: raceLinksIsLoading,
    isSuccess: raceLinksIsSuccess,
    isError: raceLinksIsError,
    error: raceLinksError,
  } = useGetRaceLinksQuery({
    daysFromToday: moment(date).diff(moment(date), "days"),
  });

  const [runners, setRunners] = useState([]);

  const proxyPrefix = "https://raceform.shitpicks.com/";

  const oddsString = `https://raceform.shitpicks.com/https://json.tab.co.nz/odds/${moment(
    date
  ).format("YYYY-MM-DD")}/2/${raceNumber}`;

  let tabRaceLink = null;

  if (raceLinksIsSuccess) {
    const meetingDetails = raceLinks.find(
      (a) =>
        a?.race?.meetingNumber === raceMeeting && a?.race?.number === raceNumber
    );
    tabRaceLink = `https://www.tab.co.nz/racing/meeting/${meetingDetails?.meeting?.id}/race/${meetingDetails?.id}/`;
  }

  let content;

  console.log(raceForm);

  if (raceFormIsLoading) {
    content = <div text="Loading...">loading</div>;
  } else if (raceFormIsSuccess) {
    content = race?.entries.map((horse) => {
      if (trifecta) {
        return (
          <HorseCardTrifecta
            horse={horse}
            horseKey={null}
            favourites={favourites}
            raceForm={raceForm}
            updateHorse={null}
            odds={raceOdds}
            currentTab={"basic"}
            user={user}
            databaseReferenceString={databaseReferenceString}
            key={horse.name}
            setSingleHorse={setSingleHorse}
          />
        );
      } else
        return (
          <HorseCard
            horse={horse}
            horseKey={null}
            favourites={favourites}
            raceForm={raceForm}
            updateHorse={null}
            odds={raceOdds}
            currentTab={"basic"}
            user={user}
            databaseReferenceString={databaseReferenceString}
            key={horse.name}
            setSingleHorse={setSingleHorse}
          />
        );
    });
  } else if (raceFormIsError) {
    console.log("form is error");

    content = <div>Error getting Race Form</div>;
  }

  return (
    <div
      style={{
        width: "100%",
        boxSizing: "border-box",
        minHeight: "100%",
      }}
    >
      {!isLoading && (
        <div
          style={{
            display: "flex",
            width: "100%",
            flex: 1,
            flexDirection: "column",
            boxSizing: "border-box",
            minHeight: "100vh",
            paddingBottom: "100px",
            border: "solid 4px",
            borderColor: raceGroupBorderColor,
          }}
        >
          <RaceDetails
            race={race}
            updateBorder={setRaceGroupBorderColor}
            tabRaceLink={tabRaceLink}
            raceDetails={raceForm}
          />{" "}
          {trifecta && (
            <div style={{ width: "100%", display: "flex" }}>
              <Button
                variant="contained"
                disableElevation
                sx={{ width: "100%", flex: "1", margin: "10px" }}
                color="secondary"
              >
                $$$ Trifectas $$$
              </Button>
            </div>
          )}
          <div style={{ padding: "10px 0px 15px 15px" }}>{content}</div>
          <Fab
            color="success"
            aria-label="add"
            sx={{
              border: "solid white 2px",
              position: "fixed",
              left: "10px",
              bottom: "65px",
            }}
            disabled={raceNumber === 1}
            onClick={() => {
              if (trifecta) {
                navigate(
                  `/trifecta/${date}/meeting/${raceMeeting}/race/${
                    raceNumber - 1
                  }`
                );
              } else
                navigate(
                  `/races/${date}/meeting/${raceMeeting}/race/${raceNumber - 1}`
                );
            }}
          >
            <BackIcon />
          </Fab>
          <Fab
            color="success"
            aria-label="add"
            sx={{
              border: "solid white 2px",
              position: "fixed",
              right: "10px",
              bottom: "65px",
            }}
            disabled={raceNumber >= raceMeetingDetails?.races.length}
            onClick={() => {
              if (trifecta) {
                navigate(
                  `/trifecta/${date}/meeting/${raceMeeting}/race/${
                    raceNumber + 1
                  }`
                );
              } else
                navigate(
                  `/races/${date}/meeting/${raceMeeting}/race/${raceNumber + 1}`
                );
            }}
          >
            <ForwardIcon />
          </Fab>
        </div>
      )}
    </div>
  );
}
