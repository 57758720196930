import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BottomNav from "./BottomNav";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function TopNav(props) {
  const { navBarColor, navBarTextColor } = useSelector((state) => state.user);
  let navigate = useNavigate();
  const { removeCookie } = props;
  return (
    <Box sx={{ flex: 1, height: "72px" }} className="headerNavBox">
      <AppBar
        position="fixed"
        sx={{
          margin: "8px",
          boxSizing: "border-box",
          width: "calc(100% - 16px)",
          borderRadius: "12px",
          backgroundColor: navBarColor,
          transition: "background-color 0.5s ease color",
          color: navBarTextColor,
        }}
        className="headerNavBar"
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Link onClick={() => navigate(-1)}>
            <ArrowBackIcon sx={{ color: navBarTextColor }} />
          </Link>
          <p>💩 Picks</p>
          <BottomNav removeCookie={() => removeCookie()}></BottomNav>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
