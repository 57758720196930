import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";

import { setUser } from "../features/user/userSlice";

const containerStyle = {
  backgroundColor: "#ffffff24",
  width: "400px",
  height: "600px",
  border: "solid white 1px",
  borderRadius: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexDirection: "column",
};

function Login(props) {
  // const {setUser} = props
  const dispatch = useDispatch();

  const users = [
    {
      name: "Dan M",
      FavouriteJockey: "James Macdonald",
      color: "Green",
      initials: "DM",
    },
    {
      name: "Dan H",
      FavouriteJockey: "Sam Spratt",
      color: "Red",
      initials: "DH",
    },
    {
      name: "Pete C",
      FavouriteJockey: "Sam Spratt",
      color: "#d87dd9",
      initials: "PC",
    },
    {
      name: "Josh O",
      FavouriteJockey: "Sam Spratt",
      color: "Blue",
      initials: "JO",
    },
  ];

  const UserBadge = (props) => {
    return (
      <div
        style={{ display: "flex", margin: "10px", cursor: "pointer" }}
        onClick={() => onUserSelection(props.user.name)}
        key={props.user.name}
      >
        <Avatar sx={{ bgcolor: props.user.color }}>
          {props.user.initials}
        </Avatar>
        <Typography
          variant="h6"
          component="h2"
          style={{ marginLeft: "10px", alignSelf: "center" }}
        >
          {props.user.name}
        </Typography>
      </div>
    );
  };

  function askNotificationPermission() {
    return new Promise((resolve, reject) => {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then(resolve);
      } else {
        Notification.requestPermission(resolve);
      }
    });
  }

  function checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  }

  const onUserSelection = (user) => {
    askNotificationPermission();
    dispatch(setUser(user));
  };

  const usersIcons = users.map((user) => (
    <UserBadge user={user} key={user.name} />
  ));

  return (
    <div className="App">
      <header className="App-header">
        <div style={containerStyle}>
          <Typography variant="h5" component="h2" style={{ margin: "30px" }}>
            Who are you?
          </Typography>
          {usersIcons}
        </div>
      </header>
    </div>
  );
}
export default Login;
