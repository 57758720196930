import { createSlice } from "@reduxjs/toolkit";
//import { fetchCount } from './counterAPI';

const users = [
  {
    name: "Dan M",
    FavouriteJockey: "James Macdonald",
    color: "Green",
    initials: "DM",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/racingpicks-b8b05.appspot.com/o/users%2Fdm.jpg?alt=media&token=a8e7f809-f313-41e1-b2cd-58bb1b8c9c37",
  },
  {
    name: "Dan H",
    FavouriteJockey: "Sam Spratt",
    color: "Red",
    initials: "DH",
    avatar: null,
  },
  {
    name: "Pete C",
    FavouriteJockey: "Sam Spratt",
    color: "#d87dd9",
    initials: "PC",
    avatar: null,
  },
  {
    name: "Josh O",
    FavouriteJockey: "Sam Spratt",
    color: "Blue",
    initials: "JO",
    avatar: null,
  },
];

let currentUser = null;
let currentLoggedIn = false;

const getCookie = (name) =>
  document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))?.at(2);

if (getCookie("user") != null && getCookie("user") !== undefined) {
  currentUser = decodeURI(getCookie("user"));
  currentLoggedIn = true;
}

const initialState = {
  currentUser: currentUser,
  loggedIn: currentLoggedIn,
  viewComments: true,
  allUsers: users,
  navBarColor: "#1976d2",
  changeNavBarTextColor: "white",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
/*
export const incrementAsync = createAsyncThunk(
  'counter/fetchCount',
  async (amount) => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);
*/

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUser: (state, action) => {
      state.loggedIn = true;
      state.currentUser = action.payload;
      //setCookie("user", action.payload, { path: "/" })
      document.cookie = `user=${action.payload}; path=/`;
    },
    clearUser: (state, action) => {
      document.cookie = `user=${action.payload}; expires=Thu, 01 Jan 1970 00:00:01 GMT"; path=/`;
      state.loggedIn = false;
      state.currentUser = null;
    },
    toggleComments: (state, action) => {
      state.viewComments = action.payload;
    },
    changeNavBarColor: (state, action) => {
      state.navBarColor = action.payload;
    },
    changeNavBarTextColor: (state, action) => {
      state.navBarTextColor = action.payload;
    },
  },
});

export const {
  setUser,
  clearUser,
  toggleComments,
  changeNavBarColor,
  changeNavBarTextColor,
} = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const loggedIn = (state) => state.loggedIn;

export default userSlice.reducer;
