import { useState, useEffect } from "react";
import _ from "lodash";
import {
  useGetFFWINByIdQuery,
  useGetTrifectaOddsMutation,
} from "../features/api/apiSlice";

const currency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export default function TrifectaResultsPanel(props) {
  const { eventId, runners } = props;
  const [runnersNumberObj, setRunnersNumberObj] = useState({});

  const { data: ffWin } = useGetFFWINByIdQuery({
    eventId: eventId,
  });

  const [getTrifectaOdds, { data }] = useGetTrifectaOddsMutation();

  useEffect(() => {
    const runnerObj = {};
    const runnerNumberObj = {};
    const outcomesArray = runners.map((o) => {
      const runnerId = ffWin?.find((r) => r.name === o).id;
      const runnerNumber = ffWin?.find((r) => r.name === o).runnerNumber;
      runnerObj[runnerId] = o;
      runnerNumberObj[runnerId] = runnerNumber;
      return runnerId;
    });
    setRunnersNumberObj(runnerNumberObj);

    if (outcomesArray.length > 0) {
      getTrifectaOdds({ runners: outcomesArray }).unwrap();
    }
  }, [ffWin, runners, getTrifectaOdds]);

  const avgPayout = Math.round(
    _.meanBy(data?.legs[0].lineCombinations, "price")
  );
  const minPayout = _.minBy(data?.legs[0].lineCombinations, "price");
  const maxPayout = _.maxBy(data?.legs[0].lineCombinations, "price");
  console.log(avgPayout, minPayout, maxPayout);

  return (
    <div
      className="myList"
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#e9f6fd",
        marginTop: "20px",
        borderRadius: "10px",
        justifyContent: "center",
        padding: "10px",
        alignItems: "center",
        paddingBottom: "15px",
      }}
    >
      <h4 style={{ margin: "6px" }}>Payout:</h4>
      <div>
        Min:{" "}
        {!isNaN(minPayout?.price) && currency.format(minPayout?.price) + " - "}
        {minPayout?.price > 0
          ? runnersNumberObj?.[minPayout?.outcomes?.[0].id] +
            ", " +
            runnersNumberObj?.[minPayout?.outcomes?.[1].id] +
            ", " +
            runnersNumberObj?.[minPayout?.outcomes?.[2].id]
          : ""}
      </div>
      <div>
        Max:{" "}
        {!isNaN(maxPayout?.price) && currency.format(maxPayout?.price) + " - "}
        {maxPayout?.price > 0
          ? runnersNumberObj?.[maxPayout?.outcomes?.[0].id] +
            ", " +
            runnersNumberObj?.[maxPayout?.outcomes?.[1].id] +
            ", " +
            runnersNumberObj?.[maxPayout?.outcomes?.[2].id]
          : ""}
      </div>{" "}
      <div>Average: {!isNaN(avgPayout) && currency.format(avgPayout)}</div>
    </div>
  );
}
