import { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import _ from "lodash";
import moment from "moment";
import Button from "@mui/material/Button";
import { initializeApp } from "firebase/app";
import {
  useGetScheduleQuery,
  useGetRaceResultsQuery,
  useGetRaceLinksQuery,
} from "../features/api/apiSlice";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { useSelector } from "react-redux";
import TrifectaResultsPanel from "../components/TrifectaResultsPanel";

export default function TrifectaResults(props) {
  const navigate = useNavigate();
  const { date, updateRaceMeeting, updateRaceNumber, upcomingRaceScreen } =
    props;
  const [myList, setMyList] = useState([]);
  const [uiItem, setUiItem] = useState([]);
  const todaysDate = moment().format("YYYY-MM-DD");

  const { data } = useGetScheduleQuery(moment(date).format("YYYY-MM-DD"));
  const { data: results, isSuccess } = useGetRaceResultsQuery(
    moment(date).format("YYYY-MM-DD")
  );

  const { allUsers: users } = useSelector((state) => state.user);

  const { data: raceLinks, isSuccess: raceLinksIsSuccess } =
    useGetRaceLinksQuery({
      daysFromToday: moment(date).diff(moment(todaysDate), "days"),
    });

  const firebaseConfig = {
    apiKey: "AIzaSyBTgM9UQZJgy5WHtynvchywyfF0Klul4Ck",
    authDomain: "racingpicks-b8b05.firebaseapp.com",
    databaseURL: "https://racingpicks-b8b05-default-rtdb.firebaseio.com",
    projectId: "racingpicks-b8b05",
    storageBucket: "racingpicks-b8b05.appspot.com",
    messagingSenderId: "975858243227",
    appId: "1:975858243227:web:d435522fd5ab90f00dd83e",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const database = getDatabase(app);

  const starCountRef = ref(
    database,
    "raceMeetings/" + moment(date).format("YYYYMMDD")
  );
  onValue(starCountRef, (snapshot) => {
    if (snapshot.exists()) {
      const snapshotData = snapshot.val();

      const horses = [];
      Object.keys(snapshotData).forEach((meeting) => {
        Object.keys(snapshotData[meeting]).forEach((race) => {
          Object.keys(snapshotData[meeting][race]).forEach((horse) => {
            if (snapshotData[meeting][race][horse].trifecta !== undefined) {
              horses.push({
                horse: snapshotData[meeting][race][horse],
                meeting: meeting,
                race: race,
                users: snapshotData[meeting][race][horse].trifecta,
              });
            }
          });
        });
      });

      if (!_.isEqual(horses, myList)) {
        setMyList(horses);
      }
    } else if (!_.isEqual([], myList)) setMyList([]);
  });

  useEffect(() => {
    let meetings = _.uniq(_.map(myList, (a) => a.meeting.split("Meeting")[1]));

    meetings = meetings?.sort((a, b) => {
      return a - b;
    });

    const ui = [];
    meetings?.forEach((meeting) => {
      const currentMeetingName = data.meetings.find(
        (a) => a.number === Number(meeting)
      ).venue;
      ui.push(
        <h2
          style={{
            textAlign: "center",
            margin: "30px 10px 20px 10px",
            color: "#0063c3",
            borderBottom: "solid #0063c3 1px",
            paddingBottom: "10px",
          }}
        >
          Meeting {meeting} - {currentMeetingName}
        </h2>
      );
      const races = _.uniq(
        myList.filter((race) => race.meeting.split("Meeting")[1] === meeting),
        "race"
      );

      let raceID;

      const uniqRaces = _.uniq(_.map(races, (race) => race.race));
      uniqRaces.forEach((b) => {
        const raceNumber = b.split("Race")[1];

        let tabRaceLink = "http://google.com";
        let raceName;
        let raceValue;
        let raceTime;

        if (raceLinksIsSuccess) {
          const meetingDetails = raceLinks.find(
            (a) =>
              a?.race?.meetingNumber === Number(meeting) &&
              a?.race?.number === Number(raceNumber)
          );
          tabRaceLink = `https://www.tab.co.nz/racing/meeting/${meetingDetails?.meeting?.id}/race/${meetingDetails?.id}/`;
          raceName = meetingDetails?.race?.name;
          raceValue = meetingDetails?.race?.stake;
          raceTime = moment(
            meetingDetails?.race?.startTime.slice(0, -5)
          ).format("h:mm a");
          raceID = meetingDetails?.id;
        } else {
          const race = data?.meetings
            .find((a) => a.number === Number(meeting))
            ?.races.find((a) => a.number === Number(raceNumber));
          console.log(data);
          raceName = race?.name;
          raceValue = race?.stake;
          raceTime = race?.norm_time;
        }

        ui.push(
          <div style={{ display: "flex", marginTop: "40px" }}>
            <div
              style={{
                fontWeight: "bold",
                flex: "5 1 0%",
                whiteSpace: "nowrap",
                textAlign: "left",
                overflow: "hidden",
                justifyContent: "left",
                textOverflow: "ellipsis",
                paddingLeft: "0px",
              }}
              onClick={() => {
                navigate(
                  `/trifecta/${moment(date).format(
                    "YYYYMMDD"
                  )}/meeting/${Number(meeting)}/race/${Number(raceNumber)}`
                );
              }}
              component={RouterLink}
            >
              <p
                style={{
                  margin: "0px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                Race {raceNumber} - {raceName}
              </p>
              <p style={{ margin: "3px 0px", fontWeight: "normal" }}>
                {moment(raceTime).format("h:mm A")} - {raceValue}
              </p>
            </div>
            <Button
              style={{ fontWeight: "bold", marginLeft: "15px", flex: 1 }}
              href={tabRaceLink}
              variant="outlined"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/racingpicks-b8b05.appspot.com/o/TAB_Logo.jpg?alt=media&token=8c593cbb-7cdb-4dfb-b9c7-b4b79408134f"
                style={{ height: "22px" }}
                alt="TAB Logo"
              />
            </Button>
          </div>
        );

        let horseLine = [];
        let runners = [];
        myList
          .filter(
            (race) =>
              race.meeting.split("Meeting")[1] === meeting && race.race === b
          )
          .forEach((c) => {
            let winner = "";
            if (isSuccess) {
              const raceResults = results
                .find((meet) => meet.number === Number(meeting))
                .races.find((rac) => rac.number === Number(raceNumber));
              const raceWinner = raceResults.placings.find(
                (winner) => winner.rank < 4
              )?.name;
              if (raceWinner === c.horse.selection.name) {
                winner = "✅";
              } else if (
                raceWinner !== c.horse.selection.name &&
                raceWinner !== undefined
              )
                winner = "❌";
              runners.push(c.horse.selection.name);
            }

            const avatars = c.users?.map((user) => (
              <Avatar
                sx={{ bgcolor: users.find((a) => a.name === user).color }}
                src={users.find((a) => a.name === user).avatar}
                key={users.find((a) => a.name === user).name}
              >
                {users.find((a) => a.name === user).initials}
              </Avatar>
            ));

            horseLine.push(
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "8px",
                  padding: "4px",
                  paddingLeft: "8px",
                  borderRadius: "5px",
                }}
                className="whatsHotBar"
              >
                <span style={{ fontSize: "0.9em" }}>
                  {c.horse.selection.number}. {c.horse.selection.name} -{" "}
                  {c.horse.selection.jockey}
                  {"   "}
                  {winner}
                </span>
                <AvatarGroup max={4}>{avatars}</AvatarGroup>
              </div>
            );
          });
        ui.push(<div>{horseLine}</div>);
        if (runners.length === 3)
          ui.push(<TrifectaResultsPanel runners={runners} eventId={raceID} />);
      });
    });
    setUiItem(ui);
  }, [
    myList,
    updateRaceMeeting,
    updateRaceNumber,
    upcomingRaceScreen,
    data,
    results,
    isSuccess,
    date,
    navigate,
    raceLinks,
    raceLinksIsSuccess,
    users,
  ]);

  return (
    <div className="myList">
      <div
        style={{
          backgroundColor: "white",
          paddingBottom: "70px",
          textAlign: "left",
          padding: "20px",
          paddingTop: "20px",
          minHeight: "100vh",
        }}
      >
        {" "}
        <div style={{ width: "100%", display: "flex" }}>
          <Button
            variant="contained"
            disableElevation
            sx={{ width: "100%", flex: "1" }}
            color="secondary"
          >
            $$$ Trifectas $$$
          </Button>
        </div>
        {uiItem}
      </div>
    </div>
  );
}
