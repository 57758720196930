import { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import _ from "lodash";
import moment from "moment";
import Button from "@mui/material/Button";
import { initializeApp } from "firebase/app";
import { useSelector } from "react-redux";
import {
  useGetScheduleQuery,
  useGetRaceResultsQuery,
  useGetRaceLinksQuery,
} from "../features/api/apiSlice";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function MyList(props) {
  const navigate = useNavigate();
  const { date, updateRaceMeeting, updateRaceNumber, upcomingRaceScreen } =
    props;
  const [myList, setMyList] = useState([]);
  const [uiItem, setUiItem] = useState([]);
  const { currentUser: user, allUsers: users } = useSelector(
    (state) => state.user
  );
  const [pageUser, setPageUser] = useState(user);

  const { data } = useGetScheduleQuery(moment(date).format("YYYY-MM-DD"));
  const { data: results, isSuccess } = useGetRaceResultsQuery(
    moment(date).format("YYYY-MM-DD")
  );

  const { data: raceLinks, isSuccess: raceLinksIsSuccess } =
    useGetRaceLinksQuery({
      daysFromToday: moment(date).diff(moment(date), "days"),
    });

  const firebaseConfig = {
    apiKey: "AIzaSyBTgM9UQZJgy5WHtynvchywyfF0Klul4Ck",
    authDomain: "racingpicks-b8b05.firebaseapp.com",
    databaseURL: "https://racingpicks-b8b05-default-rtdb.firebaseio.com",
    projectId: "racingpicks-b8b05",
    storageBucket: "racingpicks-b8b05.appspot.com",
    messagingSenderId: "975858243227",
    appId: "1:975858243227:web:d435522fd5ab90f00dd83e",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const database = getDatabase(app);

  const starCountRef = ref(
    database,
    "raceMeetings/" + moment(date).format("YYYYMMDD")
  );
  onValue(starCountRef, (snapshot) => {
    if (snapshot.exists()) {
      const snapshotData = snapshot.val();

      const horses = [];
      Object.keys(snapshotData).forEach((meeting) => {
        Object.keys(snapshotData[meeting]).forEach((race) => {
          Object.keys(snapshotData[meeting][race]).forEach((horse) => {
            if (snapshotData[meeting][race][horse].users?.includes(pageUser)) {
              horses.push({
                horse: snapshotData[meeting][race][horse],
                meeting: meeting,
                race: race,
              });
            }
          });
        });
      });

      if (!_.isEqual(horses, myList)) {
        setMyList(horses);
      }
    } else if (!_.isEqual([], myList)) setMyList([]);
  });

  useEffect(() => {
    let meetings = _.uniq(_.map(myList, (a) => a.meeting.split("Meeting")[1]));

    meetings = meetings.sort((a, b) => {
      return a - b;
    });

    const ui = [];
    meetings.forEach((meeting) => {
      const currentMeetingName = data.meetings.find(
        (a) => a.number === Number(meeting)
      ).venue;
      ui.push(
        <h2>
          Meeting {meeting} - {currentMeetingName}
        </h2>
      );
      const races = _.uniq(
        myList.filter((race) => race.meeting.split("Meeting")[1] === meeting),
        "race"
      );

      const uniqRaces = _.uniq(_.map(races, (race) => race.race));
      uniqRaces.forEach((b) => {
        const raceNumber = b.split("Race")[1];

        let tabRaceLink = "http://google.com";
        let raceName;

        if (raceLinksIsSuccess) {
          const meetingDetails = raceLinks.find(
            (a) =>
              a?.race?.meetingNumber === Number(meeting) &&
              a?.race?.number === Number(raceNumber)
          );
          tabRaceLink = `https://www.tab.co.nz/racing/meeting/${meetingDetails?.meeting?.id}/race/${meetingDetails?.id}/`;
          raceName = meetingDetails?.race?.name;
        }

        ui.push(
          <div style={{ display: "flex" }}>
            <Button
              style={{
                fontWeight: "bold",
                flex: "5 1 0%",
                whiteSpace: "nowrap",
                textAlign: "left",
                overflow: "hidden",
                justifyContent: "left",
                textOverflow: "ellipsis",
                paddingLeft: "0px",
              }}
              onClick={() => {
                navigate(
                  `/races/${moment(date).format("YYYYMMDD")}/meeting/${Number(
                    meeting
                  )}/race/${Number(raceNumber)}`
                );
              }}
              component={RouterLink}
            >
              Race {raceNumber} - {raceName}
            </Button>
            <Button
              style={{ fontWeight: "bold", marginLeft: "15px", flex: 1 }}
              href={tabRaceLink}
              variant="outlined"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/racingpicks-b8b05.appspot.com/o/TAB_Logo.jpg?alt=media&token=8c593cbb-7cdb-4dfb-b9c7-b4b79408134f"
                style={{ height: "22px" }}
                alt="TAB Logo"
              />
            </Button>
          </div>
        );

        myList
          .filter(
            (race) =>
              race.meeting.split("Meeting")[1] === meeting && race.race === b
          )
          .forEach((c) => {
            let winner = "";
            if (isSuccess) {
              const raceResults = results
                .find((meet) => meet.number === Number(meeting))
                .races.find((rac) => rac.number === Number(raceNumber));
              const raceWinner = raceResults.placings.find(
                (winner) => winner.rank === 1
              )?.name;
              if (raceWinner === c.horse.selection.name) {
                winner = "✅";
              }
            }
            ui.push(
              <p>
                {c.horse.selection.name} {winner}
              </p>
            );
          });
      });
    });
    setUiItem(ui);
  }, [
    myList,
    updateRaceMeeting,
    updateRaceNumber,
    upcomingRaceScreen,
    data?.meetings,
    results,
    isSuccess,
    date,
    navigate,
    raceLinks,
    raceLinksIsSuccess,
  ]);

  return (
    <div className="myList">
      <div style={{ paddingBottom: "10px" }}>
        <h3 style={{ color: "white" }}>View picks for user:</h3>
        {users.map((a) => (
          <Button
            variant="outlined"
            style={{ margin: "5px" }}
            onClick={() => setPageUser(a.name)}
            key={a.name}
          >
            {a.name}
          </Button>
        ))}
      </div>
      <div
        style={{
          backgroundColor: "white",
          paddingBottom: "70px",
          textAlign: "left",
          padding: "20px",
          paddingTop: "20px",
        }}
      >
        {uiItem}
      </div>
    </div>
  );
}
