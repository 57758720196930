import { useState, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Rating from "@mui/material/Rating";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { styled } from "@mui/material/styles";
import { createComment } from "../../functions/createComment";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { getDatabase, ref } from "firebase/database";
import { initializeApp } from "firebase/app";
import { useListVals } from "react-firebase-hooks/database";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetScheduleQuery,
  useGetRaceFormQuery,
  useGetRaceOddsQuery,
} from "../../features/api/apiSlice";
import moment from "moment";
import Fab from "@mui/material/Fab";
import BackIcon from "@mui/icons-material/ArrowBack";
import ForwardIcon from "@mui/icons-material/ArrowForward";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const firebaseConfig = {
  apiKey: "AIzaSyBTgM9UQZJgy5WHtynvchywyfF0Klul4Ck",
  authDomain: "racingpicks-b8b05.firebaseapp.com",
  databaseURL: "https://racingpicks-b8b05-default-rtdb.firebaseio.com",
  projectId: "racingpicks-b8b05",
  storageBucket: "racingpicks-b8b05.appspot.com",
  messagingSenderId: "975858243227",
  appId: "1:975858243227:web:d435522fd5ab90f00dd83e",
};

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

export default function HorseCard(props) {
  let { raceNumber, raceMeeting, date, runner } = useParams();
  const navigate = useNavigate();
  const { data } = useGetScheduleQuery(moment(date).format("YYYY-MM-DD"));
  const [state, setState] = useState({
    Firm: true,
    Good: true,
    Soft: true,
    Heavy: true,
    Synthetic: true,
    Distance: false,
    Track: false,
    Jockey: false,
    Barrier: false,
    Top3: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const {
    Firm,
    Good,
    Soft,
    Heavy,
    Synthetic,
    Distance,
    Track,
    Jockey,
    Barrier,
    Top3,
  } = state;

  const meeting = data?.meetings.find((a) => a.number === Number(raceMeeting));

  const race = meeting?.races.find((a) => a.number === Number(raceNumber));

  const horse = race?.entries.find((a) => a.number === Number(runner));

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const database = getDatabase(app);

  const [favourites] = useListVals(
    ref(
      database,
      "raceMeetings/" + date + "/Meeting" + raceMeeting + "/Race" + raceNumber
    )
  );

  //Get RaceForm
  const { data: raceForm } = useGetRaceFormQuery(race?.runnerString);

  const { data: odds } = useGetRaceOddsQuery({
    date: date,
    meeting: raceMeeting,
    race: raceNumber,
  });

  const [comment, setComment] = useState(null);
  const backButton = useRef(null);

  const { currentUser, allUsers: users } = useSelector((state) => state.user);

  const horseDetails = raceForm?.data?.runners?.find(
    (a) => a.raceDetails.number === horse?.number
  );

  const favId = favourites?.find((o) => o.selection.name === horse?.name);

  let bgColor = "white";
  let avatars = null;
  let comments = null;

  if (favId !== undefined) {
    avatars = favId.users?.map((user) => (
      <Avatar
        sx={{ bgcolor: users.find((a) => a.name === user).color }}
        src={users.find((a) => a.name === user).avatar}
        key={users.find((a) => a.name === user).name}
      >
        {users.find((a) => a.name === user).initials}
      </Avatar>
    ));
    // debugger;
  }

  const [rawComments] = useListVals(
    ref(database, "horseComments/" + horse?.name)
  );

  if (rawComments != null) {
    let sortedComments = rawComments;
    sortedComments.sort((a, b) => b.timeStamp - a.timeStamp);

    comments = sortedComments.map((note) => (
      <CardContent
        sx={{
          textAlign: "left",
          width: "100%",
          padding: "10px 16px",
          boxSizing: "border-box",
          display: "flex",
          flex: 1,
        }}
      >
        <Avatar
          sx={{
            bgcolor: users.find((a) => a.name === note.user).color,
            borderColor: "black",
            marginRight: "10px",
          }}
          src={users.find((a) => a.name === note.user).avatar}
          key={users.find((a) => a.name === note.user).name}
        >
          {users.find((a) => a.name === note.user).initials}
        </Avatar>{" "}
        <Typography variant="body1" component="body1">
          {note.comment}
        </Typography>
      </CardContent>
    ));
  }
  const currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const form = (
    <>
      <Typography
        sx={{ mb: 0.5, lineHeight: "initial" }}
        variant="overline"
        color="text.secondary"
        component="p"
      >
        J: {horse?.jockey} ({horse?.weight})
      </Typography>
      <Typography
        sx={{ mb: 0.5, lineHeight: "initial" }}
        variant="overline"
        color="text.secondary"
        component="p"
      >
        T: {horseDetails?.raceDetails.trainer.name}
      </Typography>
      <Typography
        sx={{ mb: 0.5, lineHeight: "initial" }}
        variant="overline"
        color="text.secondary"
        component="p"
      >
        Barrier: {horse?.barrier}
      </Typography>
      <Typography variant="body2">
        {horseDetails?.raceDetails.review}
      </Typography>
      <Typography variant="body2" key="current" fontWeight="bold">
        Total Prize Money: {currency.format(horseDetails?.totalPrize)}
      </Typography>
    </>
  );

  const trackTypes = () => {
    let trackType = [];
    const typesWeCareAbout = [
      "Firm",
      "Good",
      "Soft",
      "Heavy",
      "Distance",
      "Track",
    ];
    typesWeCareAbout.forEach((a) => {
      const raceType = a.toUpperCase();
      let textToDisplay = a + ": ";
      const currentTrackType = horseDetails?.raceStatistics.find(
        (b) => b.type === raceType
      );
      textToDisplay += currentTrackType?.starts + ":";
      textToDisplay += currentTrackType?.firsts + "-";
      textToDisplay += currentTrackType?.seconds + "-";
      textToDisplay += currentTrackType?.thirds;
      trackType.push(textToDisplay);
    });
    return trackType;
  };

  const extendedForm = (
    <div style={{ paddingTop: "5px" }}>
      <Typography variant="body1" key="current" fontWeight="bold">
        Current Track: {race?.track}
      </Typography>
      {trackTypes().map((a) => (
        <Typography variant="body1" key={a}>
          {a}
        </Typography>
      ))}
    </div>
  );

  const recentRaces = () => {
    /*
    //Apply filters based on checkbox state

    let desiredRaces = [];

    desiredRaces = _.chain(horseDetails.pastRacesDetails)
      .filter(
        (a) =>
          (a.trackCondition.replace(/[0-9]/g, "") === "Firm" && Firm) ||
          (a.trackCondition.replace(/[0-9]/g, "") === "Good" && Good) ||
          (a.trackCondition.replace(/[0-9]/g, "") === "Soft" && Soft) ||
          (a.trackCondition.replace(/[0-9]/g, "") === "Heavy" && Heavy) ||
          (a.trackCondition.replace(/[0-9]/g, "") === "Synthetic" && Synthetic)
      )
      .filter((a) => {
        if (Distance) return a.distance.distance === Number(race?.["length"]);
        else return a;
      })
      .filter((a) => {
        if (Track) return a.barrier === meeting?.code;
        else return a;
      })
      .filter((a) => {
        if (Jockey) return a.jockey.name === horse?.jockey;
        else return a;
      })
      .filter((a) => {
        if (Barrier) return a.barrier === horse?.barrier;
        else return a;
      })
      .filter((a) => {
        if (Top3) return a.finishingPosition <= 3;
        else return a;
      })
      .value();

    const displayText = desiredRaces.map((a) => (
      <>
        <Typography variant="body1" key={a}>
          {moment(a.date.replace("[UTC]", "")).format("DD-MM-YY")}{" "}
          {a.track.name} - Pos: {a.finishingPosition}/{a.fieldSize}
        </Typography>
        <Typography variant="body1" key={a}>
          Dist: {a.distance.distance} m - {a.trackCondition} - Barrier:{" "}
          {a.barrier}
        </Typography>
        <Typography variant="body1" key={a}>
          {a.jockey.name}
        </Typography>
        <Typography variant="body1" key={a} fontWeight="bold">
          {a.review !== null && a.review}
        </Typography>
        <Divider sx={{ padding: "5px", marginBottom: "5px" }} />
      </>
    ));
    return displayText;
    */
    return null;
  };

  const recentRaceDetails = (
    <div style={{ paddingTop: "5px" }}>
      <Typography variant="body1" key="current" fontWeight="bold">
        Recent Races
      </Typography>
      {recentRaces()}
    </div>
  );

  const ratings = (
    <>
      <StyledRating
        name="customized-color"
        defaultValue={3}
        getLabelText={(value: number) =>
          `${value} Heart${value !== 1 ? "s" : ""}`
        }
        precision={1}
        icon={<FavoriteIcon fontSize="inherit" />}
        sx={{ marginTop: "5px" }}
        emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
      />
    </>
  );
  const databaseReferenceString =
    "raceMeetings/" + date + "/Meeting" + raceMeeting + "/Race" + raceNumber;

  function submitComment() {
    if (comment != null) {
      createComment(horse, currentUser, comment, databaseReferenceString);
      setComment(null);
    }
  }

  return (
    <div ref={backButton} style={{ minWidth: "100%" }}>
      <div
        style={{
          backgroundColor: "white",
          margin: "15px",
          borderRadius: "15px",
          overflow: "hidden",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "90px",
              alignItems: "stretch",
              placeContent: "center",
              flexWrap: "wrap",
              overflow: "hidden",
              borderRadius: "0px 0px 0px 0px",
              backgroundColor: "black",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${horseDetails?.raceDetails.silkImageUri})`,
                backgroundSize: "340%",
                backgroundPosition: "center",
                backgroundPositionY: "-156px",
                width: "100%",
                height: "100%",
                filter: "blur(8px) brightness(0.85)",
                willChange: "filter",
                overflow: "hidden",
              }}
            ></div>
            <div
              style={{
                height: "100%",
                display: "flex",
                position: "relative",
                top: "-100%",
                boxSizing: "border-box",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Avatar
                src={horseDetails?.raceDetails.silkImageUri}
                sx={{
                  width: 56,
                  height: 56,
                  backgroundColor: "white",
                  border: "solid white 2px",
                  margin: "0 auto",
                  marginTop: "10px",
                }}
              ></Avatar>
              <Typography
                sx={{
                  mb: 0.5,
                  lineHeight: "initial",
                  marginTop: "4px",
                  fontWeight: "bold",
                  fontSize: "0.94em",
                  filter: "drop-shadow(3px 1px 5px black)",
                  color: "white",
                }}
                variant="body1"
                color="text.secondary"
              >
                {horseDetails?.lastFivePlacings}
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#ffffffb3",
                  padding: "15px 0px",
                }}
              >
                <Typography
                  sx={{ mb: 0.5, lineHeight: "initial" }}
                  variant="subtitle2"
                  color="text.secondary"
                >
                  W: $
                  {odds
                    ?.find((a) => a.number === horse?.number)
                    ?.ffwin?.toFixed(2)}
                </Typography>
                <Typography
                  sx={{ mb: 0.5, lineHeight: "initial" }}
                  variant="subtitle2"
                  color="text.secondary"
                >
                  P: $
                  {odds
                    ?.find((a) => a.number === horse?.number)
                    ?.ffplc?.toFixed(2)}
                </Typography>
              </div>
            </div>
          </div>
          <Card
            key={horse?.name}
            sx={{
              flex: 1,
              width: "calc(100% - 110px)",
              borderRadius: "0px",
              backgroundColor: bgColor,
              display: "flex",
              overflow: "initial",
              textAlign: "right",
              boxShadow: "none",
            }}
          >
            <CardContent
              sx={{ textAlign: "left", width: "100%", padding: "10px 16px" }}
            >
              <Typography variant="h6" component="div">
                {horse?.number}. {horse?.name}
              </Typography>
              {form}
            </CardContent>
          </Card>
        </div>
        <Divider>
          <Chip label="FORM" style={{ padding: "20px" }} />
        </Divider>
        <div style={{ display: "flex", width: "100%", marginBottom: "25px" }}>
          <Card
            key={horse?.name}
            sx={{
              flex: 1,
              margin: "10px 0px 0px 0px",
              width: "100%",
              borderRadius: "0px",
              backgroundColor: bgColor,
              display: "flex",
              overflow: "initial",
              textAlign: "right",
              boxShadow: "none",
            }}
          >
            <CardContent
              sx={{ textAlign: "left", width: "100%", padding: "10px 16px" }}
            >
              {extendedForm}
            </CardContent>
          </Card>
        </div>
        <Divider>
          <Chip label="Recent Races" style={{ padding: "20px" }} />
        </Divider>
        <div style={{ display: "flex", width: "100%", marginBottom: "25px" }}>
          <Card
            key={horse?.name}
            sx={{
              flex: 1,
              margin: "10px 0px 0px 0px",
              width: "100%",
              borderRadius: "0px",
              backgroundColor: bgColor,
              display: "flex",
              overflow: "initial",
              textAlign: "right",
              boxShadow: "none",
            }}
          >
            <CardContent
              sx={{
                textAlign: "left",
                width: "100%",
                padding: "10px 16px",
              }}
            >
              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Firm}
                        onChange={handleChange}
                        name="Firm"
                      />
                    }
                    label="Firm"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Good}
                        onChange={handleChange}
                        name="Good"
                      />
                    }
                    label="Good"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Soft}
                        onChange={handleChange}
                        name="Soft"
                      />
                    }
                    label="Soft"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Heavy}
                        onChange={handleChange}
                        name="Heavy"
                      />
                    }
                    label="Heavy"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Synthetic}
                        onChange={handleChange}
                        name="Synthetic"
                      />
                    }
                    label="Synthetic"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Distance}
                        onChange={handleChange}
                        name="Distance"
                      />
                    }
                    label="Distance"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Track}
                        onChange={handleChange}
                        name="Track"
                      />
                    }
                    label="Track"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Jockey}
                        onChange={handleChange}
                        name="Jockey"
                      />
                    }
                    label="Jockey"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Barrier}
                        onChange={handleChange}
                        name="Barrier"
                      />
                    }
                    label="Barrier"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Top3}
                        onChange={handleChange}
                        name="Top3"
                      />
                    }
                    label="Top 3"
                  />
                </FormGroup>
              </FormControl>

              {recentRaceDetails}
            </CardContent>
          </Card>
        </div>
        <Divider>
          <Chip label="MY TEAM'S RATINGS" style={{ padding: "20px" }} />
        </Divider>
        <div style={{ display: "flex", width: "100%", marginBottom: "25px" }}>
          <Card
            key={horse?.name}
            sx={{
              flex: 1,
              margin: "10px 0px 0px 0px",
              width: "100%",
              borderRadius: "0px",
              backgroundColor: bgColor,
              display: "flex",
              overflow: "initial",
              textAlign: "right",
              boxShadow: "none",
            }}
          >
            <CardContent
              sx={{
                textAlign: "left",
                width: "100%",
                padding: "10px 16px",
                display: "flex",
              }}
            >
              <div
                style={{ flex: "1", display: "flex", flexDirection: "column" }}
              >
                <Typography variant="body1" component="p">
                  Ratings (Broken)
                </Typography>
                {ratings}
              </div>
              <div
                style={{ flex: "1", display: "flex", flexDirection: "column" }}
              >
                <Typography variant="body1" component="p">
                  Favourited by Team
                </Typography>
                {avatars?.length > 0 && (
                  <AvatarGroup max={4}>{avatars}</AvatarGroup>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
        <Divider>
          <Chip label="CHAT" style={{ padding: "20px" }} />
        </Divider>
        <div style={{ display: "flex", width: "100%", paddingBottom: "100px" }}>
          <Card
            key={horse?.name}
            sx={{
              flex: 1,
              margin: "10px 0px 0px 0px",
              width: "100%",
              borderRadius: "0px",
              backgroundColor: bgColor,
              display: "flex",
              overflow: "initial",
              textAlign: "right",
              flexDirection: "column",
              boxShadow: "none",
            }}
          >
            <CardContent
              sx={{
                textAlign: "left",
                width: "100%",
                padding: "10px 16px",
                boxSizing: "border-box",
              }}
            ></CardContent>
            <CardContent
              sx={{
                textAlign: "left",
                width: "100%",
                padding: "10px 16px",
                boxSizing: "border-box",
                display: "flex",
              }}
            >
              <TextField
                id="outlined-multiline-static"
                label="Add comment"
                multiline
                value={comment}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setComment(event.target.value);
                }}
                fullWidth
              />
              <IconButton
                aria-label="submit"
                onClick={submitComment}
                disabled={comment == null}
              >
                <SendIcon />
              </IconButton>
            </CardContent>
            {comments != null ? (
              comments
            ) : (
              <div
                style={{
                  textAlign: "left",
                  marginLeft: "20px",
                  marginTop: "15px",
                }}
              >
                No comments yet...
              </div>
            )}
          </Card>
        </div>
        <Fab
          color="success"
          aria-label="add"
          sx={{
            border: "solid white 2px",
            position: "fixed",
            left: "10px",
            bottom: "65px",
          }}
          disabled={Number(runner) === 1}
          onClick={() =>
            navigate(
              `/races/${date}/meeting/${raceMeeting}/race/${raceNumber}/runner/${
                Number(runner) - 1
              }`
            )
          }
        >
          <BackIcon />
        </Fab>
        <Fab
          color="success"
          aria-label="add"
          sx={{
            border: "solid white 2px",
            position: "fixed",
            right: "10px",
            bottom: "65px",
          }}
          disabled={Number(runner) >= raceForm?.data?.runners.length}
          onClick={() =>
            navigate(
              `/races/${date}/meeting/${raceMeeting}/race/${raceNumber}/runner/${
                Number(runner) + 1
              }`
            )
          }
        >
          <ForwardIcon />
        </Fab>
      </div>
    </div>
  );
}
